<template>
  <div class="knowledgeQuestionsAndAnswers">
    <div class="title">
      <a-page-header
        class="knowledgeQuestionsAndAnswers_a_page_header"
        :title="knowledgeName"
        sub-title="知识库问答"
        @back="() => $router.go(-1)"
      />
    </div>

    <div class="content_box scroll-dom">
      <div class="content" v-if="fileCount">
        <!-- 主体区域 -->
        <!-- <a-spin :spinning="loading"> -->
        <div class="content_ wh-full p-20 !p-t-0 !p-b-150 m-t-20">
          <div v-if="!conversationList.length" class="empty_text">
            你好！我是你的专属知识库，可以帮助你在众多文件中快速精准查找你所需，助你工作提效。你可以随意提问想要了解的内容…
          </div>
          <!-- 问答渲染区域 -->
          <div
            v-for="item in conversationList"
            :key="item.id"
            class="question_box flex-col"
          >
            <div class="question_ m-t-16 flex justify-end w-full p-r-10">
              <div
                class="question_text bg-#4273fe color-#fff p-4 !p-l-20 !p-r-20 rounded-10 max-w-71%"
              >
                <span>{{ item.question }}</span>
              </div>
            </div>

            <!-- v-show="item.answer.length" -->
            <div class="answer_box m-t-16 flex justify-start w-full p-l-10">
              <div
                class="bg-#f5f6fa !p-l-20 !p-r-20 !p-b-30 !p-t-30 rounded-10 max-w-81% answer"
              >
                <div class="answer-text" v-html="item.answer"></div>

                <!-- 加载中 -->
                <a-spin v-if="item.loading" :spinning="item.loading" />

                <!-- 渲染衍生问题 -->
                <div
                  v-if="item.derivedQuestions && item.derivedQuestions.length"
                >
                  <div
                    v-for="(question, index) in item.derivedQuestions"
                    :key="'question-' + index"
                    class="derived-question"
                    @click="handleDerivedQuestionClick(question)"
                  >
                    <span class="derived-question-index">{{ index + 1 }}.</span>
                    <span class="derived-question-text">{{ question }}</span>
                  </div>
                </div>

                <!-- 渲染文档来源 -->
                <div v-if="item.docSources && item.docSources.length">
                  <span class="derived-question-from">引用来源:</span>
                  <div
                    v-for="(doc, index) in item.docSources"
                    :key="'doc-' + index"
                    class="derived-question-info"
                    @click="handleDocSourceClick(index, item.id)"
                  >
                    <a-popover trigger="click" placement="rightBottom">
                      <template slot="content">
                        <div class="popver_targetDoc_source">
                          <div class="popver_title">
                            内容来源于：
                            <h-icon
                              style="width: 20px; margin: 0 6px"
                              class="file-icon-img"
                              :type="
                                targetDocFileName.length &&
                                targetDocFileName.match(/\.(\w+)$/)[1]
                              "
                              alt=""
                            />
                            {{ targetDocFileName }}
                          </div>
                          <div class="empty"></div>
                          <div
                            class="targetDocFileContent_box"
                            v-html="targetDocFileContent"
                          ></div>
                        </div>
                      </template>
                      <span class="derived-question-info-txt">{{
                        doc.source
                      }}</span>
                    </a-popover>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </a-spin> -->
      </div>

      <div class="content_empty" v-else>
        <div>
          <img
            src="@/assets/images/ai_document_create_empty.png"
            width="180px"
            alt=""
          />
        </div>
        <div class="empty_text">
          暂无学习成功数据，请先在知识库上传文件再进行问答
        </div>
        <div class="add-file">
          <!-- <a-button type="primary" sizi="small" @click="addFormDoc">
            立即添加
          </a-button> -->

          <a-dropdown :trigger="['click']">
            <a-button type="primary" sizi="small"> 立即添加 </a-button>

            <a-menu slot="overlay">
              <a-menu-item key="1" @click="addFormDoc">
                <a-icon type="folder" />从云文档添加
              </a-menu-item>
              <a-menu-item key="2" @click="uploaderFromLocal">
                <a-icon type="global" />本地上传
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </div>
    </div>

    <div class="knowledgeQuestionsAndAnswers_footer" v-if="fileCount">
      <a-input
        @keyup.enter="handleConversation"
        class="_footer_ipt"
        v-model="prompt"
        placeholder="请输入您想要询问的内容"
      >
        <!-- <a-icon slot="suffix" type="audio" /> -->
      </a-input>

      <div class="_footer_right">
        <img
          @click="handleConversation"
          src="@/assets/svg/send.svg"
          style="width: 38px; cursor: pointer"
          alt=""
        />
      </div>
    </div>

    <addFile
      v-if="showAddFile"
      :showAddFile="showAddFile"
      :addFileType="addFileType"
      @closeAddFile="closeAddFile"
      @addFilesFn="addFilesFn"
    />
  </div>
</template>

<script>
import addFile from "@/components/GlobalDocTool/components/addFile/index.vue";
import MarkdownIt from "markdown-it";
import mdKatex from "markdown-it-katex";
import mila from "markdown-it-link-attributes";
import hljs from "highlight.js";
import { PageHeader as APageHeader } from "ant-design-vue"; // 导入具体的组件
import { splitChatMsg, splitChatAllData } from "@/utils/index";
export default {
  name: "knowledgeQuestionsAndAnswers",
  components: {
    APageHeader,
    addFile,
  },
  data() {
    return {
      addFileType: "editKnowledge",
      showAddFile: false,
      fileCount: 1,
      loading: false,
      targetDocFileName: "",
      targetDocFileContent: "",
      mdi: null, // 用于存储 MarkdownIt 实例
      knowledgeQuestionsAndAnswersInfoDataList: [], //  缓存聊天记录衍生问题推荐问题等信息
      derivedQuestionAnswerInfo: {}, // 衍生问题答案信息
      conversationList: [], //  问答记录
      prompt: "",
      systemMessage:
        "你是Dana AI研发的大语言模型，回答问题时你将严格依据文档内容进行回答，并遵从以下问答原则：1、回答内容较多时，在确保信息完整不改变原文意思情况下进行精炼概括；2、回答内容较少时，直接照搬原文内容；3、若段落内容与询问主题不符，将在相应部分标注“信息缺失：”，并尽可能利用现有信息辅助解答。4、如遇计算需求，将逐步展示计算过程，确保每一步都源自段落信息或基本逻辑推理。5、确保回复使用 简体中文，表述清晰，逻辑分段，搜索出现多个答案，请分条列出，然后进行总结概括。",
      options: {
        // conversationId: "",  需要上下文时conversationId为上一条消息返回的conversationId
        // parentMessageId: "", 父级消息 id 此参数用来控制对话请求是否带上下文，需要上下文时parentMessageId为上一条消息的id
      },
      knowledgeName: "", // 知识库名称
      knowledgeId: "", // 知识库id
      // fileListEleMaxHeight: 550,
      name: "知识库",
      pageNum: 1,
      tempConversationListbyIdForVuex: {},
    };
  },
  created() {
    //   this.getKnowlgeBaseList();
    console.log("路由传参query", this.$route.query);
    this.knowledgeName = this.$route.query.knowledgeName;
    this.knowledgeId = this.$route.query.knowledgeId;
    this.fileCount = this.$route.query.knowledgeFileDetail.completedCount;
    // this.getKnowledgeFileList();
    this.tempConversationListbyIdForVuex = JSON.parse(
      JSON.stringify(this.conversationListbyIdForVuex)
    );
    this.conversationList = this.tempConversationListbyIdForVuex[
      this.knowledgeId
    ]?.length
      ? this.tempConversationListbyIdForVuex[this.knowledgeId]
      : [];
  },
  mounted() {
    this.initMarkdownIt(); // 初始化 MarkdownIt 实例
  },
  watch: {
    uploadSuccessForKnowledgeBasefromLoaclFlag(newVal) {
      if (newVal) {
        setTimeout(() => {
          // this.getKnowledgeFileList();
          this.addFileToKnowledgeFn([
            this.uploadSuccessForKnowledgeBasefromLoaclFileId,
          ]);
        }, 10);
        setTimeout(() => {
          this.$store.commit(
            "knowledgeBase/set_uploadSuccessForKnowledgeBase",
            false
          );
        }, 500);
      }
    },
  },
  computed: {
    conversationListbyIdForVuex() {
      return this.$store.state.knowledgeBase.conversationListbyIdForVuex;
    },
    uploadSuccessForKnowledgeBasefromLoaclFlag() {
      return this.$store.state.knowledgeBase
        .uploadSuccessForKnowledgeBasefromLoaclFlag;
    },
    uploadSuccessForKnowledgeBasefromLoaclFileId() {
      return this.$store.state.knowledgeBase
        .uploadSuccessForKnowledgeBasefromLoaclFileId;
    },
  },
  beforeDestroy() {
    this.mdi = null;

    this.conversationList &&
      this.conversationList.length &&
      this.conversationList.forEach((item) => {
        item.loading = false;
      });

    this.tempConversationListbyIdForVuex[this.knowledgeId] = JSON.parse(
      JSON.stringify(this.conversationList)
    );

    this.$store.commit(
      "knowledgeBase/setConversationListbyIdForVuex",
      this.tempConversationListbyIdForVuex
    );
  },
  methods: {
    // 从本地上传
    uploaderFromLocal() {
      console.log("触发了本地上传吗-----------？");

      this.$store.commit("upload/SET_UPLOADCLICK", "");
    },

    // 点击添加文件确认按钮
    addFilesFn(selectedFiles) {
      const ids = selectedFiles.map((item) => item.id);
      console.log("准备为知识库添加文件---", ids);
      this.addFileToKnowledgeFn(ids);
      this.closeAddFile();
    },

    // 调用接口添加文件到知识库
    async addFileToKnowledgeFn(ids) {
      const params = {
        knowledgeIds: [this.knowledgeId],
        userFileIds: ids,
      };
      console.log("从云文档添加文件到知识库的参数", params);
      try {
        const res = await this.$apis.addFileToKnowledgeHttp(params);
        console.log("添加文件到知识库", res);
        // 好像就云文档的后端返回状态码code是字符串，其他本来就是数字
        this.$message.success("添加成功");
        setTimeout(() => {
          // this.getKnowledgeFileList();
          // this.fileCount = 99; // 模拟数字，只要不为0即可，添加了文件进来，可以问答了
          this.$router.push({
            name: "editKnowledge",
            query: {
              knowledgeId: this.knowledgeId,
              knowledgeName: this.knowledgeName,
            },
          });
        }, 10);
      } catch (error) {
        console.log("添加文件到知识库--error", error);
      }
    },
    // 关闭添加文件的弹窗
    closeAddFile() {
      this.showAddFile = false;
    },
    // 从云文档添加
    addFormDoc() {
      console.log("测试一下，从云文档添加");

      this.$store.dispatch("docTool/async_setActiveMenu", "editKnowledge");
      setTimeout(() => {
        this.showAddFile = true;
      }, 200);
    },
    // 滚动到底部
    scrollToBottom() {
      const container = document.querySelector(".scroll-dom");
      container &&
        container.scrollTo({
          top: container.scrollHeight,
          behavior: "smooth",
        });
    },

    highlightBlock(str, lang) {
      return `<pre class="code-block-wrapper whitespace-pre-line"><div class="code-block-header"><span class="code-block-header__lang">${lang}</span><span class="code-block-header__copy"></span></div><code class="hljs code-block-body ${lang}">${str}</code></pre>`;
    },
    formatText(text) {
      return this.mdi.render(text);
    },
    initMarkdownIt() {
      this.mdi = new MarkdownIt({
        linkify: true,
        highlight: (code, language) => {
          const validLang = !!(language && hljs.getLanguage(language));
          if (validLang) {
            const lang = language || "";
            return this.highlightBlock(
              hljs.highlight(code, { language: lang }).value,
              lang
            );
          }
          return this.highlightBlock(hljs.highlightAuto(code).value, "");
        },
      });
      this.mdi.use(mila, { attrs: { target: "_blank", rel: "noopener" } });
      this.mdi.use(mdKatex, {
        blockClass: "katexmath-block rounded-md p-[10px]",
        errorColor: " #cc0000",
      });
    },
    // 常规问答
    handleConversation() {
      // this.loading = true;
      let tempInfo = {};
      const question = this.prompt;
      const tempId = Date.now().toString(); // 使用当前时间戳作为临时 ID
      // 立即将问题 push 到 conversationList
      this.conversationList.push({
        question,
        answer: "", // 回答先设置为''
        id: tempId,
        loading: true,
      });
      this.prompt = "";

      setTimeout(() => {
        this.scrollToBottom();
      }, 100);

      // window.$showLoadingToast({
      //   message: "请稍后",
      //   forbidClick: true,
      //   duration: 0,
      // });

      const params = {
        options: this.options,
        prompt: question,
        repositoryId: this.knowledgeId,
        systemMessage: this.systemMessage,
      };

      console.log("知识库问答传参", params);
      this.$apis
        .chatMessageSendHttp(params, {}, (res) => {
          console.log("---res", res);
          console.log("---res.target", res.target);
          // this.loading = false;

          let xhr = res.target;
          let text = xhr.responseText;
          tempInfo = splitChatAllData(text);
          console.log("tempInfo", tempInfo);
          let answer = this.formatText(splitChatMsg(text));
          console.log("answer", answer);
          // 找到对应的问题，更新答案
          let index = this.conversationList.findIndex(
            (item) => item.id === tempId
          );
          if (index !== -1) {
            this.conversationList[index].answer = answer;
            this.conversationList[index].id = tempInfo.id; // 更新真实的ID
            this.conversationList[index].loading = false;
          } else {
            // 返回消息中断的时候是没有返回消息id的
            if (tempInfo.id) {
              let idx = this.conversationList.findIndex(
                (item) => item.id == tempInfo.id
              );
              if (idx !== -1) {
                this.conversationList[idx].answer = answer;
                this.conversationList[idx].id = tempInfo.id; // 更新真实的ID
                this.conversationList[idx].loading = false;
              }
            } else {
              let idxTemp = this.conversationList.findIndex(
                (item) => item.id == tempId
              );
              this.conversationList[idxTemp].loading = false;
            }
          }

          this.options = {
            conversationId: tempInfo.conversationId,
            parentMessageId: tempInfo.id,
          };
          // if (!isRecommendation) window.$closeToast();

          this.scrollToBottom();

          // window.$closeToast()
        })
        .then((res) => {
          console.log("完整结果--res.target", res.target);

          // let xhr = res.target;
          // let text = xhr.responseText;
          // let tempInfo = splitChatAllData(text);
          // let answer = this.formatText(splitChatMsg(text));
          // const index = this.conversationList.findIndex(
          //   (item) => item.id === tempId
          // );
          // if (index !== -1) {
          //   this.conversationList[index].answer = answer;
          //   this.conversationList[index].id = tempInfo.id; // 更新真实的ID
          // } else {
          //   const idx = this.conversationList.findIndex(
          //     (item) => item.id === tempInfo.id
          //   );
          //   if (idx !== -1) {
          //     this.conversationList[idx].answer = answer;
          //     this.conversationList[idx].id = tempInfo.id; // 更新真实的ID
          //   }
          // }

          // 如果是推荐问题的--
          // if (isRecommendation) {
          console.log("提问的是初始的推荐问题--需要获取衍生问题");
          // recommendationProblemFlag.value = false
          // window.$showLoadingToast({
          //   message: '请稍后',
          //   forbidClick: true,
          //   duration: 0,
          // })
          if (tempInfo.id) {
            this.guessWhatYouAreAsking(tempInfo.parentMessageId, tempInfo.id);
          }
          // }
        })
        .catch((err) => {
          console.log("常规问答报错--err", err);
          let idx = this.conversationList.findIndex(
            (item) => item.id == tempInfo.id
          );
          if (idx !== -1) {
            this.conversationList[idx].loading = false;
          }
        })
        .finally(() => {
          // if (!isRecommendation)
          //   window.$closeToast()
          this.scrollToBottom();
        });
    },
    async guessWhatYouAreAsking(parentMessageId, messageId) {
      // this.loading = true;
      const params = {
        parentMessageId, // 问题消息id
        messageId, // 回答消息id
        repositoryId: this.knowledgeId,
      };

      let idx = this.conversationList.findIndex((item) => item.id == messageId);
      if (idx !== -1) {
        this.conversationList[idx].loading = true;
      }

      console.log("猜你想问，及数据来源--params", params);

      try {
        const res = await this.$apis.answerCorrelationHttp(params);

        let idx_ = this.conversationList.findIndex(
          (item) => item.id == messageId
        );
        if (idx_ !== -1) {
          this.conversationList[idx_].loading = false;
        }

        this.derivedQuestionAnswerInfo = res.data;
        console.log("衍生问题答案--", this.derivedQuestionAnswerInfo);

        const derivedQuestions =
          this.derivedQuestionAnswerInfo.derived_questions;
        const doc = this.derivedQuestionAnswerInfo.doc;

        const idx = this.conversationList.findIndex(
          (item) => item.id === messageId
        );

        if (idx !== -1) {
          // 添加衍生问题
          if (derivedQuestions && derivedQuestions.length) {
            this.conversationList[idx].derivedQuestions = derivedQuestions;

            this.$forceUpdate();
            this.$nextTick(() => {
              setTimeout(() => {
                this.scrollToBottom();
              }, 200);
            });
          }

          // 添加文档来源
          if (doc && doc.length) {
            for (let i = 0; i < doc.length; i++) {
              const item = doc[i];
              const filename = item.source;
              const match = filename && filename.match(/^(\d+)\.([^.]+)$/);
              if (match) {
                const number = match[1];
                const extensionName = match[2];
                try {
                  const res = await this.$apis.KnowledgeGetFileNameHttp({
                    userFileId: number,
                  });
                  const originalFileName = res.data;
                  item.source = `${originalFileName}.${extensionName}`;
                } catch (apiError) {
                  console.log("获取文件名失败，使用默认值:", apiError);
                  item.source = `新测试文档-${extensionName}`;
                }
              } else {
                item.source = "新测试文档-.docx";
              }
            }
            this.conversationList[idx].docSources = doc;
            this.$nextTick(() => {
              setTimeout(() => {
                this.scrollToBottom();
              }, 200);
            });
          }
        }
      } catch (error) {
        console.log("衍生问题接口报错", error);
      } finally {
        console.log("此时的数据源", this.conversationList);
        this.$forceUpdate();
        this.$nextTick(() => {
          setTimeout(() => {
            this.scrollToBottom();
            this.loading = false;
          }, 200);
        });
      }
    },

    handleDerivedQuestionClick(question) {
      console.log("用户点击了衍生问题:", question);
      this.prompt = question;
      this.handleConversation();
    },

    handleDocSourceClick(index, messageId) {
      console.log("用户点击了衍生问题-文档来源:", index, messageId);
      const selectedDoc = this.conversationList.find(
        (item) => item.id === messageId
      ).docSources[index];
      this.targetDocFileName = selectedDoc.source;
      this.targetDocFileContent = this.formatText(
        selectedDoc.document.length && selectedDoc.document[0]?.text
      );
      console.log("-文档来源--targetDocFileName:", this.targetDocFileName);
      console.log(
        "-文档来源文件信息-- this.targetDocFileContent",
        this.targetDocFileContent
      );
      // this.$refs.docPreviewModal.open();
    },
  },
};
</script>

<style lang="less" scoped>
.knowledgeQuestionsAndAnswers {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-top: 0px !important;
  background-color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  .title {
    user-select: none;
    width: 100%;
    font-weight: 600;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .knowledgeQuestionsAndAnswers_a_page_header {
      padding: 0 !important;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }

  .content_box {
    flex: 1;
    width: 70%;
    overflow: auto;
    border: 1px solid #f5f6fa;
  }

  .content_empty {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .empty_text {
      margin-top: 20px;
      margin-bottom: 30px;
      color: #999;
    }
  }

  .content {
    user-select: none;

    // 问答区域
    .content_ {
      width: 100%;
      height: 100%;
      padding: 20px;
      padding-top: 0 !important;

      .empty_text {
        background: #f5f6fa;
        padding: 10px;
        border-radius: 10px;
        max-width: 81%;
      }

      margin-top: 20px;
      .question_box {
        display: flex;
        flex-direction: column;
        .question_ {
          margin-top: 16px;
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding-right: 10px;
          .question_text {
            background-color: #4273fe;
            color: #fff;
            padding: 4px 20px;
            border-radius: 10px;
            max-width: 71%;
          }
        }
        .answer_box {
          margin-top: 16px;
          display: flex;
          justify-content: flex-start;
          width: 100%;
          padding-left: 10px;
          .answer {
            background: #f5f6fa;
            padding: 0px 10px;
            padding-top: 14px;
            border-radius: 10px;
            max-width: 81%;
            .answer-text {
              // height: 100%;
            }
          }
        }
      }
    }
  }
  .knowledgeQuestionsAndAnswers_footer {
    margin-top: 20px;
    width: 70%;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ._footer_ipt {
      height: 100%;
      input {
        height: 100%;
      }
      .ant-input-suffix {
        .anticon-audio {
          // width: 24px !important;
          // height: 24px !important;
          cursor: pointer;
          svg {
            // width: 100% !important;
            // height: 100% !important;
          }
        }
      }
    }

    ._footer_right {
      width: 60px;
      margin-right: 0 auto !important;
      display: flex;
      justify-content: flex-end;
      // margin-left: 10px;
    }
  }
}
</style>

<style lang="less">
.popver_targetDoc_source {
  max-width: 600px;
  max-height: 600px;
  display: flex;
  flex-direction: column;

  .popver_title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    min-height: 40px;
    font-weight: 700;
    border-bottom: 1px solid #dcd7d7;
    // margin-bottom: 10px;
  }

  .targetDocFileContent_box {
    padding: 18px;
    flex: auto;
    overflow: auto;
    background-color: #f5f6fa;
  }
}

.derived-question {
  margin-top: 10px;
  color: #4273fe;
  /* 高亮颜色 */
}

.derived-question-index {
  font-weight: bold;
  margin-right: 5px;
}

.derived-question-text {
  cursor: pointer;
  color: #4731ff !important;
  /* 问题文本的颜色 */
}

.derived-question-from {
  display: inline-block;
  margin-right: 6px;
  margin-top: 4px;
  margin-bottom: 2px;
  color: #999 !important;
}

.derived-question-info {
  display: flex;
  width: 100%;
  height: 28px;
  align-items: center;
  // border-radius: 4px;
  // background-color: #cfe5fc;
  color: #019dfd !important;

  .derived-question-info-txt {
    cursor: pointer;
    // width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
  }
}
</style>
